import React, {Component} from 'react';
import axios from "axios";
import {BubbleLoader} from 'react-css-loaders';
import {Button} from "react-bootstrap";
import * as PT from "../global/PortalTools";
import {Session} from "../global/PortalTools";
import Breadcrumbs from "./Breadcrumbs";
import {Link} from "react-router-dom";
import {ST} from "../global/SystemText";

export default class UndeliveredProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            page: 1,
            paginationLimit: 5,
            data: {},
            show_count: 20,
        };
    }
    componentDidUpdate() {
        if(!this.state.loaded) {
            axios.get(Session.getItem("endpoint") + 'PortalServlet', {
                params: {
                    idMandant: Session.idMandant,
                    idCompany: Session.getItem("idCompany"),
                    idContact: Session.getItem("idContact"),
                    page: this.state.page,
                    action: "GET_UNDELIVERED_PRODUCTS"
                }
            })
                .then((response) => {
                    this.setState({
                        loaded: true,
                        data: response.data,
                        show_count: response.data.show_count
                    });
                })
                .catch((error) => {
                    PT.handleError(error, this.props.history);
                });
        }
    }
    componentDidMount() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {
            params: {
                idMandant: Session.idMandant,
                idCompany: Session.getItem("idCompany"),
                idContact: Session.getItem("idContact"),
                page: this.state.page,
                action: "GET_UNDELIVERED_PRODUCTS"
            }
        })
            .then((response) => {
                this.setState({
                    loaded: true,
                    data: response.data,
                    show_count: response.data.show_count
                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            });
    }

    render() {
        let pages = Math.ceil(this.state.data.count / this.state.show_count);
        let basename = Session.getItem("basename");

        let paginations = [];
        let {page, paginationLimit} = this.state;
        for (let i = 1; i <= pages; i++) {
            if(i === 1 || i === pages){
                paginations.push(<li key={i}>
                    <Button onClick={() => {
                        this.setState({page: i, loaded: false})
                    }} size={"sm"} style={this.state.page === i ? {
                        background: window.portalSettings.portal_pcolor,
                        color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                    } : {}}>{i}</Button>
                </li>);
                continue;
            }
            if(i < (page - paginationLimit)){
                continue;
            }
            if(i > (page + paginationLimit)){
                continue;
            }
            if(i === (page-paginationLimit) || i === (page + paginationLimit)){
                paginations.push(<li key={i}>
                    <Button disabled size={"sm"} style={this.state.page === i ? {
                        background: window.portalSettings.portal_pcolor,
                        color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                    } : {}}>...</Button>
                </li>);
                continue;
            }

            paginations.push(<li key={i}>
                <Button onClick={() => {
                    this.setState({page: i, loaded: false})
                }} size={"sm"} style={this.state.page === i ? {
                    background: window.portalSettings.portal_pcolor,
                    color: PT.getColorBrightness(window.portalSettings.portal_pcolor) < 135 ? "white" : "#333"
                } : {}}>{i}</Button>
            </li>);
        }

        if (this.state.loaded) {
            return (
                <div>
                    <div className="panel border-top-xlg" style={{borderColor: window.portalSettings.portal_pcolor}}>
                        <Breadcrumbs active={ST.get("undelivered_products", "global")}/>
                        <div className="panel-heading">
                            <h1 className="panel-title">
                                {ST.get("undelivered_products", "global")}
                            </h1>
                        </div>
                        <div className="panel-body">

                            <div className="table-responsive">
                                <table className="table table-lg">
                                    <thead>
                                    <tr>
                                        <th className="text-left">{ST.get("title", "global")}</th>
                                        <th className="text-center">{ST.get("product_order_our", "product")}</th>
                                        {Number(window.portalSettings.orderin_access) > 0 ?
                                            <th className="text-center">{ST.get("order", "global")}</th> : null
                                        }
                                        <th className="text-center">{ST.get("ordered", "global")}</th>
                                        <th className="text-center">{ST.get("issued_yet", "global")}</th>
                                        <th className="text-center">{ST.get("remaining", "global")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.data.list.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-left">{row.name}</td>
                                                <td className="text-center">{row.order_our}</td>
                                                {Number(window.portalSettings.orderin_access) > 0 ?
                                                    <td className="text-center"><Link
                                                        to={basename + "/orderin/" + row.orderin_id}>{row.orderin_number}</Link>
                                                    </td>
                                                    : null
                                                }
                                                <td className="text-center">{row.ordered + " " + row.product_unit}</td>
                                                <td className="text-center">{row.issued + " " + row.product_unit}</td>
                                                <td className="text-center">{row.remains + " " + row.product_unit}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                                <hr/>
                            </div>
                            <div className="text-center content-group-lg pt-20">
                                <ul className="pagination">
                                    <li className={this.state.page === 1 ? "disabled" : ""}><Button
                                        disabled={this.state.page === 1} onClick={() => {
                                        this.setState({page: (this.state.page - 1), loaded: false})
                                    }} size={"sm"} variant={"light"}><i className="icon-arrow-left15"/></Button>
                                    </li>
                                    {paginations.map((element, index) => {
                                        return (element);
                                    })}
                                    <li className={this.state.page === pages ? "disabled" : ""}>
                                        <Button disabled={this.state.page === pages} onClick={() => {
                                            this.setState({page: (this.state.page + 1), loaded: false})
                                        }} size={"sm"} variant={"light"}><i
                                            className="icon-arrow-right15"/></Button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (<BubbleLoader color={window.portalSettings.portal_pcolor}/>);
        }
    }
}
