import React, {Component} from 'react';
import {Button, Col, Modal, Row} from "react-bootstrap";
import {ST} from "../global/SystemText";
import * as PT from "../global/PortalTools";
import axios from "axios";
import {Session} from "../global/PortalTools";
import {Link} from "react-router-dom";

export default class DashboardProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loaded: false
        }
    }

    componentDidMount() {
        this.getCatalogueMenu();
    }
    getCatalogueMenu() {
        axios.get(Session.getItem("endpoint") + 'PortalServlet', {params: {
                idMandant: Session.idMandant,
                idCompany: Session.getItem("idCompany"),
                idContact: Session.getItem("idContact"),
                idCurrency:Session.getItem("idCurrency"),
                idLanguage:Session.getItem("idLanguage"),
                action: "GET_PORTAL_CATALOGUE_MENU"}})
            .then((response) => {
                this.setState({
                    loaded: true,
                    data: response.data.catalogues

                });
            })
            .catch((error) => {
                PT.handleError(error, this.props.history);
            })
    }

    render() {
        return (
            this.state.loaded?
            <Row>
                {this.state.data.map((element, index) => {
                    return(
                        <Col key={element.id} md={4} sm={6}>
                            <Link to={Session.getItem("basename") + "/catalogue/" + element.id}>
                                <div className="panel catalogue">
                                    <div className="panel-body p-15">
                                        <Row>
                                            <Col xs={4}>
                                                <div className="thumb thumb-fixed cat-thumb">
                                                    <img src={Session.getItem("endpoint") + element.picture} alt={element.name}/>
                                                </div>
                                            </Col>
                                            <Col xs={8}>
                                                <div className="cat-title">
                                                    {element.name}
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="clearfix"/>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                    )
                })}
                <Col >

                </Col>
            </Row>:null
        )
    }
}